<template>
    <div id="app">
      <router-view :key="componentKey"></router-view>
    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n'
  import { useUserStore } from './stores/user'
  import { onMounted, reactive, computed, watch, watchEffect, provide, ref } from 'vue'
  // import { io } from 'socket.io-client'
  import axios from 'axios';
  import {loadScript, gen_random_string} from './loadScript.js';
  import { useRouter, useRoute } from 'vue-router'
  import { initializeSocket } from './socketService.js'
  export default {
    name: 'App',
    setup() {
      // const socket = io({reconnectionAttempts: 30, reconnectionDelay:3000, reconnectionDelayMax: 15000,origins:"*"})
      // const socket = getSocket();
      const { t, locale } = useI18n()
      const userStore = useUserStore();
      const gstate = reactive({
        socket_room: 'A1B2C3D4E5F6G7H8',
      })
      const router = useRouter()
      const route = useRoute()
      const componentKey = ref(0)
      const navigateTo = (path) => {
        const targetRoute = router.resolve(path)
        // console.log("targetRoute", targetRoute)
        // console.log("targetcomponent", targetRoute.matched[0].components.default.name, "source", route.matched[0].components.default.name, targetRoute.matched[0].components.default.name===route.matched[0].components.default.name)
        if (targetRoute.meta?.login_required === true && gstate?.user_info_json?.loggedin !== true) {
          // console.log("Attempting to access login required link and server side check not triggered")
          window.location.href = '/login';
        }
        if (targetRoute.meta?.parentComponentName === null || route.meta?.parentComponentName === null) {
          console.log("Forgot to set parentComponentName", targetRoute, route)
        }
        if (targetRoute.meta?.parentComponentName === "Askai" && gstate?.mounted_components?.includes("Askai")) {
          window.location.href = path; // must reload completely to allow get_dict_from_list to work correctly. sometimes it seems there are two state.model_msg? one is empty?
        }
        if (path === route.path || (targetRoute.meta?.parentComponentName !== null && route.meta?.parentComponentName && targetRoute.meta?.parentComponentName)) { //targetRoute.matched[0].components.default === route.matched[0].components.default
          componentKey.value++
          // console.log("adding component key")
          router.push(path)
        } else {
          router.push(path)
        }
      }
      // const navigateTo = (path) => {
      //   const targetRoute = router.resolve(path)
      //   const currentRoute = router.currentRoute.value
      //   // console.log("targetRoute", targetRoute, "currentRoute", currentRoute)
      //   const index = gstate.mounted_components.findIndex(name => name === 'Askai');
      //   // if (index !== -1 && targetRoute.matched[0]?.components?.default.name === 'Askai') {
      //   //   gstate.mounted_components.splice(index, 1);
      //   //   console.log("Attempting to trigger component reload, which will also unmount Askai")
      //   //   componentKey.value++
      //   //   router.push(path)
      //   // } else {
      //   //   router.push(path)
      //   // }
      //   router.push(path)
      // }
      async function saveUserSetting(kvPairs) {
        // "Input look like this : const settingsToSave = [
        //   ['show_advanced', true],
        //   ['show_tutorial', false],
        // ];"
        try {
          const response = await fetch('/_save_user_setting', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ kv_pair: kvPairs }),
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          
          if (data.status === 'success') {
            if (!(gstate.hasOwnProperty('user_setting')) || gstate.user_setting === null) {
              gstate.user_setting = {};
            } 
            kvPairs.forEach(([key, value1]) => {
              gstate.user_setting[key] = value1;
            });
            console.log('User settings saved successfully');
          } else {
            console.error('Failed to save user settings:', data.msg);
          }

          return data;
        } catch (error) {
          console.error('Error saving user settings:', error);
          return { status: 'failed', msg: 'An error occurred while saving user settings' };
        }
      }
      function setCookie(cname, cvalue, exdays=99999) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/" + ";SameSite=Lax";
      }
    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    function reading_share() {
        return (window.location.href.includes("/x/") || window.location.href.includes("/innerx/"))
    }
    function request_to_brand() {
      let outjson = {};
      if (window.location.href.includes("fastlaw.cc"))
          {outjson = {"email":"support@fastlaw.cc", "domain_name":"fastlaw.cc","brand_name":'{{_("Fast Law")}}',"promo_msg":'{{_("Try GPT-4, ChatGPT, document translation and OCR service for free on https://fastlaw.cc . Securely translate and process your documents with our on-premise servers - no Internet connection required(sales@fastlaw.cc)")}}'}}
      else if (window.location.href.includes("kuaifa.ai"))
          {outjson = {"email":"support@kuaifa.ai", "domain_name":"kuaifa.ai","brand_name":'{{_("Kuai Fa")}}',"promo_msg":'{{_("Try GPT-4, ChatGPT, document translation and OCR service for free on https://kuaifa.ai . Securely translate and process your documents with our on-premise servers - no Internet connection required(sales@kuaifa.ai)")}}'}}
      else if (window.location.href.includes("gptbowl"))
          {outjson = {"email":"support@gptbowl.com", "domain_name":"gptbowl.com","brand_name":'{{_("GPT Bowl")}}',"promo_msg":'{{_("Try GPT-4, ChatGPT, document translation and OCR service for free on https://www.gptbowl.com . Securely translate and process your documents with our on-premise servers - no Internet connection required(sales@gptbowl.com)")}}'}}
      else
      {outjson = {"email":"support@gptbowl.com", "domain_name":"gptbowl.com","brand_name":'{{_("GPT Bowl")}}',"promo_msg":'{{_("Try GPT-4, ChatGPT, document translation and OCR service for free on https://www.gptbowl.com . Securely translate and process your documents with our on-premise servers - no Internet connection required(sales@gptbowl.com)")}}'}}
      return outjson
    }
    function getUuidFromUrl() {
          const url = window.location.href;
          let lastPart = url.split('/').pop();
          lastPart = lastPart.split(/[#?]/)[0];
          // lastPart = lastPart.replace(/[^a-zA-Z0-9-]/g, '');
          return lastPart;
      }

      gstate.domain_json = request_to_brand();
    
      onMounted(async () => {
        console.log("Mounting App")
        gstate.socket_room=gen_random_string(16);
        
        let share_uuid = getUuidFromUrl();
        // await userStore.fetchUserInfo({share_uuid: share_uuid});
        userStore.fetchUserInfo({share_uuid: share_uuid}).then(async () => {
          console.log("User info fetched");
          if (userStore.responseData) {
            gstate.user_info_json = userStore.responseData.user_info_json;
            initializeSocket(gstate);
            gstate.languages_df = userStore.responseData.languages_df;
            gstate.languages_df = JSON.parse(gstate.languages_df);
            gstate.user_setting = userStore.responseData.user_setting;
            if (!gstate.user_setting) {
              gstate.user_setting = {};
            }
            if (!gstate.user_setting.hasOwnProperty("show_advanced")) {
              gstate.user_setting.show_advanced = false;
            }
            if (!gstate.user_setting.hasOwnProperty("show_system")) {
              gstate.user_setting.show_system = false;
            }
            locale.value = userStore.responseData.locale;
            if (userStore.responseData.hasOwnProperty("shared_chat_json")) {
              gstate.shared_chat_json = userStore.responseData.shared_chat_json;
            }
            gstate.user_info_json["gptagent_dict"] = JSON.parse(gstate.user_info_json["gptagent_dict"]);
          }

          if (reading_share()) {
              
              const uuid = getUuidFromUrl();
              try {
                  const response = await axios.get(`/_chatshare/${uuid}`);
                  console.log('Share data:', response.data);
                  gstate.chatshare = response.data;
              } catch (error) {
                  console.error('Error fetching share data:', error);
                  // Handle the error (e.g., show an error message to the user)
              }
          }
        });
        
        const scriptUrls = [
          // "/static/assets/js/headroom.min.js",
          // "/static/assets/js/pixel.js",
          // "/static/assets/vendor/bootstrap/dist/js/bootstrap.min.js",
          // "/static/assets/js/corejsbundle332.js",
        ];
        const promises = scriptUrls.map(url => loadScript(url));
        try {
            await Promise.all(promises);
            console.log('All main scripts loaded successfully');
        } catch (error) {
            console.error('Failed to load external assets:', error);
        }
        console.log("Finishing Mounting App")
      })
      provide('gstate', gstate)
      provide('t', t)
      provide('locale', locale)
      // provide('socket', socket)
      provide('setCookie', setCookie)
      provide('getCookie', getCookie)
      provide('reading_share', reading_share)
      provide('getUuidFromUrl', getUuidFromUrl)
      provide('saveUserSetting', saveUserSetting)
      provide('navigateTo', navigateTo)
      
      return { t, locale, userStore, gstate, setCookie, getCookie,saveUserSetting, navigateTo, componentKey} //, socket
    }
  }
  </script>