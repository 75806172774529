import { createRouter, createWebHistory } from 'vue-router'
import { defineAsyncComponent } from 'vue';
// const Overview = () => import('./components/Overview.vue');
// const Askai = () => import('./components/Askai.vue');
const Page500 = () => import('./components/Page500.vue');
const Page400 = () => import('./components/Page400.vue');
// const Index = () => import('./components/Index.vue');
const Index = defineAsyncComponent(() => import('./components/Index.vue'))
const Overview = defineAsyncComponent(() => import('./components/Overview.vue'))
const Askai = defineAsyncComponent(() => import('./components/Askai.vue'))


import {loadScript, loadExternalAssets} from './loadScript.js';

const routes = [
  {
    path: `/x/:uuid`,
    component: () => import('./components/Askai.vue'),
    beforeEnter: (to, from, next) => {
      next()
    },
    meta: {
    onReady: () => {
        import(/* @vite-ignore */ './components/Overview.vue');
      },
      parentComponentName: 'Askai'
    }
  },
  {
    path: '/addcredit.html',
    component: () => import('./components/Addcredit.vue'),
    beforeEnter: (to, from, next) => {
      next();
    },
    meta: {
      parentComponentName: 'Addcredit'
    }
  },
  {
    path: '/addcredit',
    component: () => import('./components/Addcredit.vue'),
    beforeEnter: (to, from, next) => {
      next();
    },
    meta: {
      parentComponentName: 'Addcredit'
    }
  },
  {
    path: '/page500',
    component: () => import('./components/Page500.vue'),
    beforeEnter: (to, from, next) => {
      next();
    },
    meta: {
      parentComponentName: 'Page500'
    }
  },
  {
    path: '/refresh',
    name: 'refresh',
    component: {
      beforeRouteEnter(to, from, next) {
        next(vm => {
          const { redirect } = to.query
          const { forceRefresh } = to.state || {}
          
          if (forceRefresh) {
            vm.$router.replace({
              path: redirect,
              force: true
            })
          } else {
            vm.$router.replace(redirect)
          }
        })
      }
    }
  },
  // {
  //   path: '/index2',
  //   component: () => import('./components/Index.vue'),
  //   beforeEnter: (to, from, next) => {
  //     next();
  //   },
  //   // Use meta instead of props
  //   meta: {
  //     onReady: () => {
  //       // this works but no cached
  //       import(/* @vite-ignore */ './components/Overview.vue');
  //       import(/* @vite-ignore */ './components/Askai.vue');
  //     }
  //   }
  // }
  // {
  //   path: '/index2',
  //   component: () => import('./components/IndexWrapper.vue'),
  //   props: {
  //     mainComponent: Index,
  //     preMount: [Overview, Askai]
  //   }
  // },

    // {
    //   path: '/index2',
    //   component: () => import('./components/IndexWrapper.vue'),
    // },
];

// Generate routes for each base path

const indexPaths = ['/', '/index', '/index.html', ];
indexPaths.forEach(indexPath => {
    routes.push(
      {
        path: indexPath,
        component: () => import('./components/Index.vue'),
        beforeEnter: (to, from, next) => {
          next();
        },
        // Use meta instead of props
        meta: {
          onReady: () => {
            // this works but no cached
            import(/* @vite-ignore */ './components/Overview.vue');
            import(/* @vite-ignore */ './components/Askai.vue');
            loadExternalAssets();
          },
          parentComponentName: 'Index'
        },
        
      }
    )
  }
)

const oPaths = ['/o', '/overview'];
oPaths.forEach(oPath => {
    routes.push(
      {
        path: oPath,
        component: () => import('./components/Overview.vue'),
        beforeEnter: (to, from, next) => {
          next();
        },
        meta: {
          onReady: () => {
            import(/* @vite-ignore */ './components/Askai.vue');
            loadExternalAssets();
          },
          parentComponentName: 'Overview'
        }
      }
    )
  }
)
    

const basePaths = ['/t', '/f', '/v',  '/innerx'];
basePaths.forEach(basePath => {
  routes.push(
    {
      path: `${basePath}/:gptagentkey`,
      component: () => import('./components/Askai.vue'),
      beforeEnter: (to, from, next) => {
        next()
      },
      meta: {
      onReady: () => {
          import(/* @vite-ignore */ './components/Overview.vue');
        },
        login_required: true,
        parentComponentName: 'Askai'
      }
    },
    {
      path: `${basePath}/:gptagentkey/:model_select`,
      component: () => import('./components/Askai.vue'),
      beforeEnter: (to, from, next) => {
        next()
      },
      meta: {
      onReady: () => {
          import(/* @vite-ignore */ './components/Overview.vue');
        },
        login_required: true,
        parentComponentName: 'Askai'
      }
    }
  );
});


// Error handling routes
routes.push({ path: '/:pathMatch(.*)*', component: () => import('./components/Page400.vue') });
routes.push({ path: '/error', component: () => import('./components/Page500.vue') });

// Create and export the router
const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;