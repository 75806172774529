import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import zh_CN from './locales/zh_CN.json'
import zh_TW from './locales/zh_TW.json'
const messages = {
  en,
  zh_CN,
  zh_TW
}

const i18n = createI18n({
  locale: 'en', // set default locale
  fallbackLocale: 'en',
  messages,
  legacy: false,
})

export default i18n