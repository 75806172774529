import { createApp } from 'vue'
import { createPinia } from 'pinia';
import { loadPriorityScripts } from './loadScript';
import App from './App.vue'
import NavBar from './components/NavBar.vue'
import router from './router'
import i18n from './i18n'
import "/node_modules/flag-icons/css/flag-icons.min.css";

const app = createApp(App)
app.component('NavBar', NavBar)
app.use(router)
app.use(i18n)
app.use(createPinia());
// app.mount('#app')

async function init() {
//   await loadPriorityScripts()
  app.mount('#app')
}

init()